import React from 'react';

function AboutPage() {
    return (
        <>
        <h2>About Page</h2>
        <p>Identity Werks is an identity management company that manages the identity lifecycle and authentication so you don't have to.</p> 
        <br/>
        <p>Not only is it less work, but it is more secure because our technology allows customers to log into their Office 365 account without a user password</p>
        </>
    );
}

export default AboutPage;