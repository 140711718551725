import React from 'react';
import '../css/HomePage.css'

function HomePage() {
    return (
        <div className="center">
            <h1 className="title">Identity Werks</h1>
            <p className="description">Over 80% of breaches are from passwords</p>
            <p className="description">Identity managed service, we do the work</p>
            <p className="description">No user passwords</p>
            <p className="description">Certificate authentication for the secure business</p>
        </div>
    );
}

export default HomePage;