import React from "react";

function Header () {
    return (
        <nav>
            <a href="/">Home</a> | <a href="/about">About</a>  
        </nav>
    );
}

export default Header;
